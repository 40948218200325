import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const NotificationModalContainer = styled.div`
  width: 100%;
  background: rgba(${theme("RGBA_01")}, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 65px;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "initial")};
  z-index: 1001;
`;

export const ModalContent = styled.div<{ hasSupport?: boolean }>`
  background-color: ${theme("Background_16")};
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  position: absolute;
  bottom: 0;
  height: auto;
  width: 100%;

  img {
    height: auto;
  }

  ${mediaQuery[Screen.tablet]} {
    left: 50%;
    transform: translateX(-50%);
    max-width: 556px;
  }

  ${mediaQuery[Screen.xlDesktop]} {
    max-width: 556px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 24px;
`;

export const MarginRow = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  .title-primary {
    color: ${theme("Primary_01")};
  }
`;
