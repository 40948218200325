import { useMemo } from "react";

// Own components
import Header from "../Header";
import Link from "../Link";
import AuthProvider from "../../Contexts/AuthProvider/AuthProvider";
import FloatingHelpButton from "../FloatingHelpButton";
import HelpLinks from "../HelpLinks";
import NavigationBar from "../NavigationBar";

// Interface
import { PropsLayout } from "../../Models/Layout";

// Own styles
import { ContainerFluid, HeaderContainer, Content, Return } from "./Layout.styled";
import { useEffect, useState } from "react";
import { CouponsModal } from "@components/Modal/CouponsModal/CouponsModal.component";
import { CouponsSelector } from "@redux/Payments/Coupons/Coupons.slice";
import { ByUserSliceSelector } from "@redux/ByUser/ByUser.slice";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { accountPaymentOrdersSelector } from "@redux/PaymentOrders/PaymentOrders.slice";
import FloatingZelleButton from "../FloatingZelleButton";
import { DifferenceTime } from "../../Utilities/DifferenceTime";
import { useCoupons } from "@hooks/Payments/useCoupons";
import isEmpty from "lodash.isempty";
import IconFont from "@components/IconFont";
import Loader from "@components/Loader";
import {
  PendingTransactionsModalSelector,
  PendingTransactionsModalSlice,
} from "@redux/Payments/PendingTransactionsModal";
import { useModal } from "@hooks/Payments/useModal";

const Layout = ({
  path,
  show = true,
  loading,
  loadingMsg,
  showHelpBtn = true,
  showNavigation = false,
  rewardsFeature = false,
  ref,
  children,
}: PropsLayout) => {
  if (!show) return;

  const dispatch = useDispatch();
  const ByUser = useSelector(ByUserSliceSelector).data;
  const { couponsGrouped } = useSelector(CouponsSelector).data;
  const [showCouponsModal, setShowCouponsModal] = useState(false);
  const paymentOrders = useSelector(accountPaymentOrdersSelector);
  const transactionsZelle =
    paymentOrders?.data?.results?.filter(
      (transaction) =>
        transaction.fundType === "Zelle" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];
  const transactionsVenmo =
    paymentOrders?.data?.results?.filter(
      (transaction) =>
        transaction.fundType === "Venmo" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];

  const transactionList = useMemo(() => paymentOrders?.data, [paymentOrders?.data]);
  const { getUserCoupons } = useCoupons();

  const showPendeingTransactionsModalSelector = useSelector(PendingTransactionsModalSelector);
  const showTransactionsModal = useMemo(
    () => showPendeingTransactionsModalSelector?.show,
    [showPendeingTransactionsModalSelector]
  );
  const { modal: pendingTransactionsModal, showModal: showPendingTransactionsModal } = useModal();

  const isFirstTransaction = useMemo(() => {
    if (paymentOrders.data?.results && paymentOrders.data.results.length !== 0) return false;

    return true;
  }, [paymentOrders]);

  useEffect(() => {
    if (showTransactionsModal && (!isEmpty(transactionsZelle) || !isEmpty(transactionsVenmo))) {
      showPendingTransactionsModal({
        modalType: "pendingTransactionsModal",
        transactionList: transactionList,
        handleClose: () => {
          dispatch(PendingTransactionsModalSlice.actions.updatePendingTransactionsModal(false));
        },
      });
    }
  }, [showTransactionsModal, transactionList]);

  useEffect(() => {
    (async () => {
      if (ByUser?.userId) {
        await getUserCoupons(false);
      }
    })();
  }, []);

  return (
    <AuthProvider>
      {loading && <Loader full message={loadingMsg} />}
      <ContainerFluid $show ref={ref}>
        <HeaderContainer className="header">
          <Header />
        </HeaderContainer>
        <Content className="main-content">
          {path && (
            <Return>
              <Link href={path}>
                <IconFont name="arrow-left" size="medium" />
              </Link>
            </Return>
          )}
          {children}
          {pendingTransactionsModal}
        </Content>

        {transactionsZelle?.length ? <FloatingZelleButton /> : ""}

        {showHelpBtn && (
          <FloatingHelpButton>
            <HelpLinks />
          </FloatingHelpButton>
        )}

        {showNavigation && (
          <NavigationBar
            hasCoupons={rewardsFeature && !isFirstTransaction && !isEmpty(couponsGrouped)}
            onClickCoupons={() => setShowCouponsModal(true)}
          />
        )}
        {!isEmpty(couponsGrouped) && (
          <CouponsModal
            show={showCouponsModal}
            coupons={couponsGrouped}
            handleClose={() => setShowCouponsModal(false)}
          />
        )}
      </ContainerFluid>
    </AuthProvider>
  );
};

export default Layout;
