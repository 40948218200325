import { createSlice } from "@reduxjs/toolkit";
import { State } from "@redux/Store";

const initialState: { show: boolean } = {
  show: true,
};

export const PendingTransactionsModalSlice = createSlice({
  name: "pendingTransactionsModal",
  initialState,
  reducers: {
    updatePendingTransactionsModal: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { updatePendingTransactionsModal } = PendingTransactionsModalSlice.actions;
export const PendingTransactionsModalSelector = (state: State) => state.pendingTransactionsModal;

export default PendingTransactionsModalSlice.reducer;
