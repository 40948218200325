import { useTranslation } from "react-i18next";

// Own Hooks
import { useTimer } from "../../Hooks/useTimer";

// Own Components
import { Column, Row } from "../Grid";
import Text from "../Text";
import Link from "../Link";

// Own Styles
import { TimerContainer, TruncateText, ZelleTransactionCardContainer } from "./VenmoTransactionCard.styles";
import FlagDestination from "../FlagDestination";
import Icon from "../Icon";

export interface VenmoTransactionCardProps {
  dateAt: string;
  userName: string;
  amount: number;
  countrySender: string;
  destinationCountry: string;
  mtcn: string;
  selectedData: (amount: number, reference: string) => void;
  setShowInstructions: (show: boolean) => void;
}

const VenmoTransactionCard = (props: VenmoTransactionCardProps) => {
  const [t] = useTranslation("global");
  const { dateAt, userName, mtcn, amount, countrySender, destinationCountry, selectedData, setShowInstructions } =
    props;
  const { hours, minutes, seconds } = useTimer({
    target: new Date(dateAt),
  });

  const handleInstruction = (amount: number, reference: string) => {
    setShowInstructions(true);
    selectedData(amount, reference);
  };

  return (
    <Column span={12} as={ZelleTransactionCardContainer}>
      <Row>
        <Column span={1}>
          <FlagDestination origin={countrySender} destination={destinationCountry} />
        </Column>
        <Column span={8}>
          <TruncateText>
            <Text size={0.3} color="VenmoColor" align="left" weight="600" margin="0 12px">
              {t("venmoTransactionsModal.titleCard")} {userName}
            </Text>
          </TruncateText>
          <Text size={0.5} color="black" align="left" weight="600" margin="0 12px">
            ${amount} USD
          </Text>
          <Text size={0.3} color="VenmoColor" align="left" weight="500" margin="0 12px">
            <Link href="/" weight={600} size={0.3} variant="VenmoColor" onClick={() => handleInstruction(amount, mtcn)}>
              {t("venmoTransactionsModal.howPay")}
            </Link>
          </Text>
        </Column>
        <Column span={3}>
          {Number(hours) > 0 || Number(minutes) > 0 || Number(seconds) > 0 ? (
            <>
              <TimerContainer>
                <Icon icon="timeClockLoading" color="warningStrong" size="xsmall" />
                <Text align="right" size={0.3} color="warningStrong" weight={600} margin="0 4px">
                  {`${hours}:${minutes}:${seconds}`}
                </Text>
              </TimerContainer>
              <Text align="right" size={0.2} color="grey" weight={500} margin="2px">
                {t("venmoTransactionsModal.msgPending")}
              </Text>
            </>
          ) : (
            <>
              <TimerContainer>
                <Icon icon="timeClockStop" color="Red11" size="xsmall" />
                <Text align="right" size={0.3} color="Red11" weight={600} margin="0 0 0 4px">
                  {t("venmoTransactionsModal.msgExpired")}
                </Text>
              </TimerContainer>
              <Text align="right" size={0.2} color="Red11" weight={500} margin="2px 0">
                {t("venmoTransactionsModal.msgCancelled")}
              </Text>
            </>
          )}
        </Column>
      </Row>
    </Column>
  );
};

export default VenmoTransactionCard;
