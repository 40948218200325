import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

interface TimerProps {
  target?: Date;
}

export const useTimer = (props?: TimerProps) => {
  const [targetDate, setTargetDate] = useState<Date | undefined>(props?.target);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (targetDate) {
      const calculateTimeLeft = () => {
        const now = new Date();
        const graceTime = new Date(targetDate.getTime() + 180 * 60 * 1000);
        const diffInSeconds = differenceInSeconds(graceTime, now);
        setTimeLeft(diffInSeconds > 0 ? diffInSeconds : 0);
      };

      calculateTimeLeft();

      const timer = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [targetDate]);

  const hours = Math.floor(timeLeft / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeLeft % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (timeLeft % 60).toString().padStart(2, "0");

  return { hours, minutes, seconds, setTargetDate };
};
